<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-data-table
      :headers="headers"
      :items="supplyOrders"
      :loading="isLoadingSupplyOrders"
      :server-items-length="supplyOrdersMeta.totalItems"
      :options.sync="options"
      :items-per-page="30"
      :footer-props="{ 'items-per-page-options': [10, 30, 50] }"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="$admin.can('supplyOrder-view')"
          class="mr-2"
          color="#C6C6C6"
          @click.stop="showItem(item)"
        >
          $vuetify.icons.iconViewItem
        </v-icon>

        <v-icon class="mr-2" @click.stop="duplicate(item)">
          $vuetify.icons.iconCopyItem
        </v-icon>

        <v-icon class="mr-2" @click.stop="downloadItem(item)">
          $vuetify.icons.iconDownloadPO
        </v-icon>
      </template>
      <template v-slot:item.supply_order_status="{ item }">
        <span
          v-if="item.supply_order_status == 'cancelled'"
          class="red--text mb-2 font-weight-medium text-capitalize"
          >Cancelled</span
        >
        <span
          v-if="item.supply_order_status == 'received'"
          class="green--text mb-2 font-weight-medium text-capitalize"
          >Received</span
        >
        <span
          v-if="item.supply_order_status == 'received partially'"
          class="black--text mb-2 font-weight-medium text-capitalize"
          >Received partially</span
        >
      </template>
      <template v-slot:top>
        <v-row class="my-2">
          <v-col cols="12" sm="2" md="2">
            <v-text-field
              outlined
              clearable
              dense
              v-model="filters.search"
              :label="$t('search')"
              class=""
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" md="2">
            <v-autocomplete
              outlined
              clearable
              dense
              v-model="filters.from_provider_id"
              :items="providers"
              item-text="company_name"
              item-value="id"
              :label="$t('provider')"
              :search-input.sync="search_from_provider"
              :loading="is_loading_providers"
              no-filte
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2" md="2">
            <v-autocomplete
              outlined
              clearable
              dense
              v-model="filters.to_hub_id"
              :items="hubs"
              item-text="name"
              item-value="id"
              :label="$t('hub')"
              :search-input.sync="search_to_hub"
              :loading="is_loading_hubs"
              no-filter
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2" md="2">
            <v-menu
              v-model="order_date_start"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  clearable
                  v-model="filters.start_date"
                  :label="$t('start_delivery_date')"
                  append-icon="mdi-calendar"
                  readonly
                  chips
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.start_date"
                no-title
                color="green lighten-1"
                header-color="green lighten-1"
                @input="order_date_start = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="2" md="2">
            <v-menu
              v-model="order_date_end"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  clearable
                  v-model="filters.end_date"
                  :label="$t('end_delivery_date')"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.end_date"
                no-title
                color="primary"
                @input="order_date_end = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="2" md="2">
            <v-autocomplete
              outlined
              clearable
              dense
              v-model="filters.status"
              :items="['cancelled', 'received', 'received partially']"
              :label="$t('status')"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
    </v-data-table>
    <confirm-duplication-modal
      v-if="isDuplicateModalOpen"
      :isDuplicateModalOpen="isDuplicateModalOpen"
      :orderItem="currentOrderItem"
      :toggleDuplicateModal="() => toggleDuplicateModal()"
    >
    </confirm-duplication-modal>
    <cancel-order-modal
      v-if="isCancelModalOpen"
      :isCancelModalOpen="isCancelModalOpen"
      :orderItem="currentOrderItem"
      :toggleCancelModal="() => toggleCancelModal()"
    >
    </cancel-order-modal>
  </v-container>
</template>

<script>
import supplyOrderService from "@/store/services/supply-order-service";
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";

export default {
  components: {
    ConfirmDuplicationModal: () =>
      import("../modals/ConfirmDuplicationOrder.vue"),
    CancelOrderModal: () => import("../modals/CancelOrderModal.vue"),
  },

  computed: {
    ...mapGetters({
      isLoadingSupplyOrders: "supplyOrders/isLoadingSupplyOrders",
      supplyOrders: "supplyOrders/list",
      supplyOrdersMeta: "supplyOrders/meta",
      hubs: "hubs/activeHubs",
      providers: "providers/activeProviders",
    }),

    isVisibleDialog: {
      get() {
        return this.$store.state.supplyOrders.isVisibleDialog;
      },
      set(value) {
        this.$store.commit("supplyOrders/IS_VISIBLE_DIALOG", value);
      },
    },
    filters: {
      get() {
        return this.$store.state.supplyOrders.filters;
      },
      set(value) {
        this.$store.commit("supplyOrders/SET_FILTERS", value);
      },
    },
  },
  props: {
    isActive: {
      types: Boolean,
    },
  },

  watch: {
    options: {
      handler: debounce(async function (params) {
        if (this.isActive) {
          await this.$store
            .dispatch("supplyOrders/list", {
              ...params,
              ...this.filters,
            })
            .then(() => {})
            .catch(() => {
              this.$store.dispatch("alerts/error", "SupplyOrder not found");
            });
        }
      }, 1000),
      deep: true,
    },

    filters: {
      handler: debounce(async function (params) {
        if (params.status == null) params.supply_order_status = "history";
        else {
          params.supply_order_status = params.status;
        }
        console.log("filter", params);
        if (this.isActive) {
          await this.$store
            .dispatch("supplyOrders/list", {
              ...params,
              ...this.options,
            })
            .then(() => {})
            .catch(() => {
              this.$store.dispatch("alerts/error", "supplyOrder not found");
            });
        }
      }, 1000),
      deep: true,
    },

    search_from_provider: debounce(async function (search) {
      console.log(search);
      if (this.is_loading_providers) return;
      this.is_loading_providers = true;

      await this.$store
        .dispatch("providers/list", {
          // itemsPerPage: 30,
          search,
        })
        .then(() => {
          this.is_loading_providers = false;
        });
    }, 1000),
    search_from_status: debounce(async function (search) {
      console.log(search);
      if (search != "cancelled") search = "history";
      console.log(search);
    }, 1000),

    search_to_hub: debounce(function (search) {
      if (this.is_loading_hubs) return;
      this.is_loading_hubs = true;

      this.$store
        .dispatch("hubs/list", {
          // itemsPerPage: 30,
          search,
        })
        .then(() => {
          this.is_loading_hubs = false;
        });
    }, 1000),
  },

  data() {
    return {
      search_from_provider: "",
      search_from_status: "",
      search_to_hub: "",
      is_loading_providers: false,
      is_loading_inbounds: false,
      is_loading_hubs: false,
      is_loading_orders: false,
      options: {},
      index: 0,
      order_date_start: null,
      order_date_end: null,
      isDuplicateModalOpen: false,
      currentOrderItem: null,
      isCancelModalOpen: false,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "tracking_number",
        },
        {
          text: this.$t("from_provider"),
          align: "start",
          sortable: false,
          value: "fromProvider.company_name",
        },
        {
          text: this.$t("products_count"),
          align: "center",
          sortable: false,
          value: "products_count",
        },
        {
          text: this.$t("to_hub"),
          align: "start",
          sortable: false,

          value: "toHub.name",
        },
        {
          text: this.$t("creation_date"),
          align: "center",
          sortable: true,
          value: "created_at",
        },
        {
          text: this.$t("expected_delivery_date"),
          align: "center",
          sortable: false,
          value: "expected_delivery_date",
        },
        {
          text: this.$t("status"),
          align: "center",
          sortable: false,
          value: "supply_order_status",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          align: "end",
          sortable: false,
        },
      ],
    };
  },

  methods: {
    toggleDuplicateModal() {
      this.isDuplicateModalOpen = !this.isDuplicateModalOpen;
    },
    duplicate(item) {
      this.isDuplicateModalOpen = true;
      this.currentOrderItem = item;
    },
    toggleCancelModal() {
      this.isCancelModalOpen = !this.isCancelModalOpen;
    },
    cancel(item) {
      this.isCancelModalOpen = true;
      this.currentOrderItem = item;
    },

    async downloadItem(item) {
      const BDC = await supplyOrderService.getInvoice(item);
      const url = window.URL.createObjectURL(new Blob([BDC]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${item.tracking_number}.pdf`);
      document.body.appendChild(link);
      link.click();
    },

    showItem(item) {
      console.log(item);
      this.$router.push({
        name: "supplyOrders.view",
        params: { id: item.id },
      });
    },
  },
};
</script>
